.box {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 2% auto;
}

.description {
  font-size: 1.1rem;
  font-family: "AssistantR";
  text-align: center;
  margin: 2% auto;
  direction: rtl;
  width: 90%;
  text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
  color: rgb(64, 45, 21);
}

.title {
  font-size: 1.8rem; /* קטן יותר מ-2.5rem המקורי */
  font-family: "Assistant";
  text-align: center;
  margin: 3% auto;
  direction: rtl;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
}

.icon {
  display: flex;
  justify-content: center;
  width: 30%;
  margin: 0 auto;
}

@media only screen and (max-width: 350px) {
  .box {
      width: 300px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 3% auto;
  }
  
  .description {
      font-size: 1rem;
      font-family: "AssistantR";
      text-align: center;
      margin: 2% auto;
      direction: rtl;
      width: 90%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
      color: rgb(64, 45, 21);
  }
  
  .title {
      font-size: 1.4rem; /* קטן יותר מ-1.8rem המקורי */
      font-family: "Assistant";
      text-align: center;
      margin: 3% auto;
      direction: rtl;
      

      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
  }
  
  .icon {
      display: flex;
      justify-content: center;
      width: 30%;
      margin: 2% auto;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .box {
      width: 300px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 3% auto;
  }
  
  .description {
      font-size: 1.1rem;
      font-family: "AssistantR";
      text-align: center;
      margin: 2% auto;
      direction: rtl;
      width: 90%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
      color: rgb(64, 45, 21);
  }
  
  .title {
      font-size: 1.6rem; /* קטן יותר מ-2rem המקורי */
      font-family: "Assistant";
      text-align: center;
      margin: 3% auto;
      direction: rtl;
      

      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
  }
  
  .icon {
      display: flex;
      justify-content: center;
      width: 30%;
      margin: 2% auto;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .box {
      width: 350px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 3% auto;
  }
  
  .description {
      font-size: 1.15rem;
      font-family: "AssistantR";
      text-align: center;
      margin: 2% auto;
      direction: rtl;
      width: 95%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
      color: rgb(64, 45, 21);
  }
  
  .title {
      font-size: 1.7rem; /* קטן יותר מ-2.2rem המקורי */
      font-family: "Assistant";
      text-align: center;
      margin: 3% auto;
      direction: rtl;
      

      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
  }
  
  .icon {
      display: flex;
      justify-content: center;
      width: 25%;
      margin: 2% auto;
  }
}