.container,.containerP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.containerP{
    padding-top: 80px;
}
/* Background Elements */
.backgroundElements {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.glassCircle {
    position: fixed;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: linear-gradient(120deg, rgba(199, 163, 85, 0.05), rgba(199, 163, 85, 0.05));
    backdrop-filter: blur(15px);
    border: 1px solid rgba(199, 163, 85, 0.2);
    right: -15%;
    top: -10%;
    z-index: -1;
    transition: transform 1s ease-out;
}

.glowingOrb {
    position: fixed;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(199, 163, 85, 0.15), transparent 70%);
    left: -5%;
    bottom: -10%;
    animation: floatOrb 25s infinite ease-in-out;
}

.radialGlow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(199, 163, 85, 0.05), transparent 70%);
    mix-blend-mode: overlay;
}

@keyframes floatOrb {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(30px, -30px); }
}

.content {
    width: 100%;
    max-width: 1200px;
    padding: 1rem 0.5rem;
    z-index: 1;
}

/* Header Styles */
.header {
    text-align: center;
    margin: 2rem 0 0;
    position: relative;
}

.subtitle {
    color: #000000;
    font-size: 1.4rem;
    font-family: "AssistantR";
    font-weight: 300;
    margin-bottom: 1rem;
}

.nameContainer {
    position: relative;
}

.name {
    font-size: 3.5rem;
    color: #c7a355;
    font-family: "Dancing";
    letter-spacing: 4px;
    margin-bottom: 1.5rem;
}

.services {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #171717;
    font-size: 1.4rem;
    font-family: "AssistantR";
    position: relative;
    padding-bottom: 1rem;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.separator {
    color: #c7a355;
    font-size: 0.5rem;
}

/* Services Grid */
.servicesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin: 1rem 0;
    position: relative;
    direction: rtl;
}

.glassCard {
    position: relative;
    height: 150px;
    perspective: 1000px;
    margin: 25px auto;
    width: 350px;
}

.cardContent {
    position: relative;
    height: 100%;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(199, 163, 85, 0.2);
    border-radius: 1rem;
    margin: 2% auto;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transform-style: preserve-3d;
    overflow: hidden;
    text-align: right;
}

.cardGlow {
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at 50% 0%, rgba(199, 163, 85, 0.15), transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.glassCard:hover .cardContent {
    transform: rotateY(10deg) rotateX(5deg);
}

.glassCard:hover .cardGlow {
    opacity: 1;
}

.cardTitle {
    font-size: 1.4rem;
    color: #c7a355;
    margin-bottom: 0.6rem;
    position: relative;
    font-family: "Assistant";
}

.cardDescription {
    color: #171717;
    line-height: 1.4;
    position: relative;
    font-family: "AssistantR";
    font-size: 1rem;
}

/* Media Queries */
@media screen and (max-width: 350px) {
    .glassCircle {
        width: 200px;
        height: 200px;
        right: -35%;
        top: -20%;
    }
    
    .glowingOrb {
        width: 200px;
        height: 200px;
    }
    
    .content {
        padding: 1rem;
    }
    
    .name {
        font-size: 2rem;
    }
    
    .subtitle {
        font-size: 1rem;
    }
    
    .services {
        font-size: 1.1rem;
        gap: 0.5rem;
    }
    
    .servicesGrid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
    .glassCard {
        height: 120px;
        min-width: unset;
        width: 100%;
    }
    
    .cardTitle {
        font-size: 1.1rem;
    }
    
    .cardDescription {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 350px) and (max-width: 450px) {
    .glassCircle {
        width: 220px;
        height: 220px;
        right: -35%;
        top: -10%;
    }
    
    .glowingOrb {
        width: 250px;
        height: 250px;
    }
    
    .name {
        font-size: 2.7rem;
    }
    
    .subtitle {
        font-size: 1.2rem;
    }
    
    .services {
        font-size: 1.2rem;
        gap: 0.5rem;
    }
    
    .servicesGrid {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
    
    .glassCard {
        height: 130px;
        min-width: unset;
        width: 100%;
    }
}

@media screen and (min-width: 450px) and (max-width: 550px) {
    .glassCircle {
        width: 400px;
        height: 400px;
    }
    
    .glowingOrb {
        width: 300px;
        height: 300px;
    }
    
    .name {
        font-size: 2.5rem;
    }
    
    .subtitle {
        font-size: 1.2rem;
    }
    
    .servicesGrid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
    .glassCard {
        height: 140px;
        min-width: unset;
        width: 100%;
    }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
    .glassCircle {
        width: 450px;
        height: 450px;
    }
    
    .servicesGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }
    
    .name {
        font-size: 2.8rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
    .glassCircle {
        width: 500px;
        height: 500px;
    }
    
    .servicesGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.8rem;
    }
    
    .name {
        font-size: 3rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
    .servicesGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
    
    .name {
        font-size: 3.2rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 850px) and (max-width: 950px) {
    .servicesGrid {
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1050px) {
    .servicesGrid {
        gap: 1.8rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1150px) {
    .servicesGrid {
        gap: 2rem;
    }
    
    .glassCard {
        width: 100%;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1250px) {
    .servicesGrid {
        gap: 2rem;
    }
    
    .glassCard {
        width: 100%;
    }
}